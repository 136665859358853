import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useRouter } from 'src/routes/hooks'

import { useAuthContext } from 'src/auth/hooks'
import { toast } from 'react-toastify';
import { LoadingScreen } from 'src/components/loading-screen';
import DHLogo from "../../assets/dhl.png"
import FedexLogo from "../../assets/fedex.png"
import Logo from "../../assets/newLogo.png"
import Icon1 from "../../assets/icons/home-icon1.png"
import Icon2 from "../../assets/icons/home-icon2.png"
import Icon3 from "../../assets/icons/home-icon3.png"
import Partner1Logo from "../../assets/partner-1.png"
import Partner2Logo from '../../assets/partner-2.jpg'
import Partner3Logo from '../../assets/partner-3.png'
import Partner4Logo from '../../assets/partner-4.png';


export default function Home() {
  const { user } = useAuthContext()
  const router = useRouter()

    const [id, setId] = useState('');
    const [loading, setLoading] = useState(false)

    function handleTrackShipmet() {
      if (!id) {
         toast.error('Enter shipment id', {
           position: 'top-right',
           autoClose: 3000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           theme: 'light',
         });
        return;
      }

      if (id.length < 5) {
        toast.error('Invalid id', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        return;
      }
     
      setLoading(true)

      setTimeout(() => {
        setLoading(false)
           toast.error('No shipment record match this id', {
             position: 'top-right',
             autoClose: 3000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: 'light',
           });
      }, 5000);
      // router.push(`/shipment/track/${id}`);
    }

    if(loading){
      return (
        <LoadingScreen />
      )
    }

  return (
    <div>
      <div className="bg-center bg-cover bg-no-repeat bg-[url('https://images.pexels.com/photos/6169052/pexels-photo-6169052.jpeg')] bg-gray-700 bg-blend-multiply">
        <header className="">
          <div className="mx-auto flex h-16 max-w-screen-xl items-center gap-8 px-4 sm:px-6 lg:px-8">
            <a className="block text-blue-600" href="/">
              <span className="sr-only">Home</span>
              <img src={Logo} alt="logo" style={{ width: 70, height: 70 }} />
            </a>

            <div className="flex flex-1 items-center justify-end md:justify-end">
              <div className="flex items-center gap-4">
                <div className="sm:flex sm:gap-4">
                  {!user ? (
                    <>
                      <Link
                        to="/auth/login"
                        class="block rounded-md bg-blue-600 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-blue-700"
                      >
                        Login
                      </Link>

                      <Link
                        class="hidden rounded-md bg-gray-100 px-5 py-2.5 text-sm font-medium text-blue-600 transition hover:text-blue-600/75 sm:block"
                        to="/auth/register"
                      >
                        Register
                      </Link>
                    </>
                  ) : (
                    <Link
                      to="/dashboard"
                      class="block rounded-md bg-blue-600 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-blue-700"
                    >
                      Dashboard
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="">
          <div className="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
            <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
              Swift Shipping, Nigeria ⇄ Canada
            </h1>
            <p className="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">
              Your hassle-free shipping solution for packages between Nigeria and Canada. Ship
              confidently, arrive effortlessly with octaship
            </p>
            <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
              <input
                type="text"
                onChange={(e) => setId(e.target.value)}
                value={id}
                style={{ outline: 'none', border: 'none', padding: 10, borderRadius: 7 }}
                placeholder="Shipment ID"
              />
              <Link
                onClick={() => handleTrackShipmet()}
                className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
              >
                Track
                <svg
                  aria-hidden="true"
                  className="ml-2 -mr-1 w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </section>
      </div>
      {/* <div className="flex w-screen flex-row bg-gradient-to-l from-[#281034] to-[#280134]">
  <div className="sm:my-auto mx-auto px-4 py-16 sm:max-w-xl  md:px-24 lg:px-8 lg:py-20">
  <div className="relative shadow-xl shadow-[#280134] lg:w-1/2  mb-[30px] md:block">
      <div className="relative m-auto h-48 w-[90%] rounded-xl bg-gradient-to-r from-[#280134] to-gray-400 text-white shadow-2xl transition-transform sm:h-56 md:w-96 sm:hover:scale-110">
    <div className="absolute top-4 w-full px-8 sm:top-8">
      <div className="flex justify-between">
        <div className="">
          <p className="font-light">Name</p>
          <p className="font-medium tracking-widest">John Doe</p>
        </div>
        <img className="h-14 w-14 object-contain" src={Mlogo} />
      </div>
      <div className="pt-1">
        <p className="font-light">Card Number</p>
        <p className="tracking-more-wider font-medium">1234 5678 9012 34</p>
      </div>
      <div className="pt-4 pr-6 sm:pt-6">
        <div className="flex justify-between">
          <div className="">
            <p className="text-xs font-light">Expiry</p>
            <p className="text-base font-medium tracking-widest">08/20</p>
          </div>
          <div className="">
            <p className="text-xs font-light">Balance</p>
            <p className="text-base font-medium tracking-widest">$100</p>
          </div>

          <div className="">
            <p className="text-xs font-light">CVV</p>
            <p className="tracking-more-wider text-sm font-bold">501</p>
          </div>
        </div>
      </div>
    </div>
    </div>
      </div>
    <div className="flex flex-col items-center justify-between lg:flex-row">
      <div className="mb-10 lg:mb-0 lg:max-w-lg lg:pr-5">
        <div className="mb-6 max-w-xl">
          <div>
            <p className="bg-blue-accent-400 mb-2 inline-block rounded-full px-3 text-xs font-semibold uppercase tracking-wider text-gray-50">Secure USD Card</p>
          </div>
          <h2 className="mb-6 max-w-lg font-sans text-3xl font-bold leading-snug tracking-tight text-white sm:text-5xl sm:leading-snug">
            The only card you need for 
            <span className="my-1 inline-block rounded bg-white px-2 text-[#280134]"> online</span>
            payments
          </h2>
          <p className="text-base text-gray-200">Say goodbye to worries about limitations, exchange rates and hidden fees.</p>
        </div>
        <div className="flex flex-col items-center md:flex-row">
          <Link to="/login" className="mb-3 inline-flex h-12 w-full items-center justify-center rounded bg-white text-[#280134] px-6 font-medium tracking-wide shadow-md transition duration-200 hover:bg-violet-800 focus:outline-none md:mr-4 md:mb-0 md:w-auto">Get Started</Link>
        </div>
      </div>
 </div>
 </div>
</div> */}
      <section className="bg-white text-white">
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
          <div className="mx-auto max-w-lg text-center">
            <h2 className="text-3xl text-blue-600 font-bold sm:text-4xl">Why choose us</h2>

            <p className="mt-4 text-gray-600">
              In a sea of options, we stand out. Choose us for reliable, swift, and secure shipping
              between Canada and Nigeria. Your satisfaction is our commitment.{' '}
            </p>
          </div>

          <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            <div className="block rounded-xl border border-blue-800 p-8 shadow-xl transition hover:border-blue-500/10 hover:shadow-blue-500/10">
              <img src={Icon2} alt="" className="h-10 w-10" />
              <h2 className="mt-4 text-xl font-bold text-blue-600">Reliable Deliveries</h2>

              <p className="mt-1 text-sm text-gray-600">
                Count on us for timely and secure deliveries, ensuring your packages reach their
                destination without a hitch
              </p>
            </div>

            <div className="block rounded-xl border border-blue-800 p-8 shadow-xl transition hover:border-blue-500/10 hover:shadow-blue-500/10">
              <img src={Icon3} alt="" className="h-10 w-10" />

              <h2 className="mt-4 text-xl font-bold text-blue-600">Transparent Tracking</h2>

              <p className="mt-1 text-sm text-gray-600">
                Stay informed every step of the way with our transparent tracking system.
              </p>
            </div>

            <div className="block rounded-xl border border-blue-800 p-8 shadow-xl transition hover:border-blue-500/10 hover:shadow-blue-500/10">
              <img src={Icon1} alt="" className="h-10 w-10" />
              <h2 className="mt-4 text-xl font-bold text-blue-600">Competitive Rates</h2>

              <p className="mt-1 text-sm text-gray-600">
                Enjoy cost-effective shipping rates without compromising on the quality of service.
              </p>
            </div>
          </div>

          <div className="mt-12 text-center" />
        </div>
      </section>
      <section className="bg-white">
        <div className="py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
          <h2 className="mb-8 lg:mb-16 text-3xl font-extrabold tracking-tight leading-tight text-center text-[#007AFF] md:text-4xl">
            Our partners
          </h2>
          <div className="flex gap-5 flex-row text-gray-500 sm:gap-5 justify-center items-center">
            <a href="#" className="flex items-center mx-auto">
              <img src={DHLogo} alt="DH Logo" width={120} height={120} />
            </a>
            <a href="#" className="flex items-center mx-auto">
              <img src={Partner1Logo} alt="Fedex Logo" width={120} height={120} />
            </a>
            <a href="#" className="flex items-center mx-auto">
              <img src={Partner2Logo} alt="Fedex Logo" width={120} height={120} />
            </a>
            <a href="#" className="flex items-center mx-auto">
              <img src={Partner3Logo} alt="Fedex Logo" width={120} height={120} />
            </a>
            <a href="#" className="flex items-center mx-auto">
              <img src={Partner4Logo} alt="Fedex Logo" width={59} height={50} />
            </a>
            {/* Add more items as needed */}
          </div>
        </div>
      </section>
      {/* <section className="bg-white">
          <div className="py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
              <h2 className="mb-8 lg:mb-16 text-3xl font-extrabold tracking-tight leading-tight text-center text-gray-900 md:text-4xl">Our Partners</h2>
              <div className="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-6 dark:text-gray-400">
                  <a href="#" className="flex justify-center items-center">
                    <img src={KudaLogo} />                       
                  </a>
              </div>
          </div>
      </section> */}

      {/* <section className="py-6 text-blue-900 sm:py-16 lg:py-20">
  <div className="mx-auto max-w-screen-lg px-4 sm:px-6 lg:px-8">
    <div className="flex flex-col lg:flex-row">
      <div className="my-10 text-center sm:mt-20 lg:text-left">
        <p className="text-lg font-medium text-[#280134]">What clients say about their experience with us</p>
        <h2 className="mt-4 text-3xl font-bold text-blue-600 sm:text-4xl">Client Testimonials</h2>
        <div className="mt-8" />
      </div>

      <div className="relative mx-auto grid max-w-lg grid-cols-1 gap-6 lg:mr-0 lg:gap-10">
        <div className="flex flex-col overflow-hidden shadow-xl shadow-blue-200">
          <div className="flex flex-1 flex-col justify-between bg-white p-6 lg:py-8 lg:px-7">
            <div className="flex-1">
              <div className="flex items-center">
                <svg className="h-6 w-6 text-amber-300/70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-6 w-6 text-amber-300/70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-6 w-6 text-amber-300/70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-6 w-6 text-amber-300/70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-6 w-6 text-amber-300/70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              </div>

              <blockquote className="mt-8 flex-1">
                <p className="text-lg leading-relaxed text-blue-900">"I regularly use Octaship to send packages to my family in Nigeria. They always make sure my packages are packed securely and shipped promptly. I've never had any problems with them, and I'm always confident that my packages will arrive safely.</p>
              </blockquote>
            </div>

            <div className="mt-8 flex items-center">
              <img className="h-11 w-11 flex-shrink-0 rounded-full object-cover" src="https://images.pexels.com/photos/4890259/pexels-photo-4890259.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              <div className="ml-4">
                <p className="text-base font-bold text-blue-900">James Khawalski</p>
                <p className="mt-0.5 text-sm">Customer</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col overflow-hidden shadow-xl shadow-blue-200">
          <div className="flex flex-1 flex-col justify-between bg-white p-6 lg:py-8 lg:px-7">
            <div className="flex-1">
              <div className="flex items-center">
                <svg className="h-6 w-6 text-amber-300/70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-6 w-6 text-amber-300/70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-6 w-6 text-amber-300/70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-6 w-6 text-amber-300/70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-6 w-6 text-amber-300/70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              </div>

              <blockquote className="mt-8 flex-1">
                <p className="text-lg leading-relaxed text-blue-900">I was very impressed with the speed and efficiency of Octaship service. My package was shipped from Canada to Nigeria in just a few days, and I was able to track its progress every step of the way.
</p>
              </blockquote>
            </div>

            <div className="mt-8 flex items-center">
              <img className="h-11 w-11 flex-shrink-0 rounded-full object-cover" src="https://images.pexels.com/photos/10513822/pexels-photo-10513822.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              <div className="ml-4">
                <p className="text-base font-bold text-blue-900">Jacob Jones</p>
                <p className="text-blue-90 mt-0.5 text-sm">Customer</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col overflow-hidden shadow-xl shadow-blue-200">
          <div className="flex flex-1 flex-col justify-between bg-white p-6 lg:py-8 lg:px-7">
            <div className="flex-1">
              <div className="flex items-center">
                <svg className="h-6 w-6 text-amber-300/70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-6 w-6 text-amber-300/70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-6 w-6 text-amber-300/70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-6 w-6 text-amber-300/70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-6 w-6 text-amber-300/70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              </div>

              <blockquote className="mt-8 flex-1">
                <p className="text-lg leading-relaxed text-blue-900">I was initially hesitant to use a shipping service to send my package from Nigeria to Canada, but I'm so glad I decided to use Octaship. They were incredibly professional and helpful throughout the entire process, and my package arrived in Canada safely and on time. I would definitely recommend them to anyone looking for a reliable and affordable shipping service</p>
              </blockquote>
            </div>

            <div className="mt-8 flex items-center">
              <img className="h-11 w-11 flex-shrink-0 rounded-full object-cover" src="https://images.pexels.com/photos/3992656/pexels-photo-3992656.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              <div className="ml-4">
                <p className="text-base font-bold text-blue-900">Jenny Wilson</p>
                <p className="text-blue-90 mt-0.5 text-sm">Customer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> */}
      <section className="bg-white">
        <div className="py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
          <h2 className="mb-8 lg:mb-16 text-3xl font-extrabold tracking-tight leading-tight text-center text-gray-900 md:text-4xl">
            FAQs (Frequently Asked Questions)
          </h2>
          <div className="space-y-4">
            <details className="group [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                <h2 className="font-medium">Does you ship to other countries?</h2>

                <svg
                  className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </summary>

              <p className="mt-4 px-4 leading-relaxed text-gray-700">
                No, currently we only ship between Canada and Nigeria vice versa.
              </p>
            </details>
          </div>
        </div>
      </section>

      <footer className="bg-white">
        <div className="mx-auto max-w-screen-xl px-4 pb-6 pt-16 sm:px-6 lg:px-8 lg:pt-24">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            <div>
              <div className="flex justify-center text-blue-600 sm:justify-start">
                <img src={Logo} style={{ height: 70, width: 70 }} alt="logo" />
              </div>

              <p className="mt-6 max-w-md text-center leading-relaxed text-gray-500 sm:max-w-xs sm:text-left">
                Octaship is your one-stop solution for all your shipping needs between Canada and
                Nigeria.
              </p>
            </div>

            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-4 lg:col-span-2">
              <div className="text-center sm:text-left">
                <p className="text-lg font-medium text-gray-900">Company</p>

                <ul className="mt-8 space-y-4 text-sm">
                  <li>
                    <Link
                      className="text-gray-700 transition hover:text-gray-700/75"
                      to="/about-us"
                    >
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link className="text-gray-700 transition hover:text-gray-700/75" to="/prohibited-items">
                      Prohibited Items
                    </Link>
                  </li>
                  <li>
                    <Link className="text-gray-700 transition hover:text-gray-700/75" to="/terms">
                      Terms And Conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-gray-700 transition hover:text-gray-700/75"
                      to="/privacy-policy"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="text-center sm:text-left">
                <p className="text-lg font-medium text-gray-900">Contact us</p>

                <ul className="mt-8 space-y-4 text-sm">
                  <li>
                    <a
                      className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
                      href="/"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 shrink-0 text-gray-900"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>

                      <span className="flex-1 text-gray-700">support@octaship.com</span>
                    </a>
                  </li>

                  <li>
                    <a
                      className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
                      href="/"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 shrink-0 text-gray-900"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                        />
                      </svg>

                      <span className="flex-1 text-gray-700">+16478654531</span>
                    </a>
                  </li>

                  <li className="flex items-start justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 shrink-0 text-gray-900"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>

                    <address className="-mt-0.5 flex-1 not-italic text-gray-700">
                      5000 Hwy 7, Markham, ON. L3R 4M9.
                    </address>
                  </li>
                </ul>
              </div>

              <div className="text-center sm:text-left">
                <p className="text-lg font-medium text-gray-900">Connect with us</p>
                <ul className="mt-8 flex justify-center gap-6 sm:justify-start md:gap-8">
                  <li>
                    <a
                      href="https://www.instagram.com/octaship?igsh=NmN3Y2cwb2txd3gx&utm_source=qr"
                      rel="noreferrer"
                      target="_blank"
                      className="text-blue-700 transition hover:text-blue-700/75"
                    >
                      <span className="sr-only">Instagram</span>
                      <svg
                        className="h-6 w-6"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>

                  <li>
                    <a
                      href="/"
                      rel="noreferrer"
                      target="_blank"
                      className="text-blue-700 transition hover:text-blue-700/75"
                    >
                      <span className="sr-only">Twitter</span>
                      <svg
                        className="h-6 w-6"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}